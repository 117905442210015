
<template>
<div class="col-md-7 col-sm-7 mt-10 boxmain">
          <section class="container boxrouting">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('question.contact')}}
                </h2>
              </div>
            </div>
          </section>
          <section class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="service-detail-text">
                  <p class=" text-center" v-if="message"> {{$t('question.success_question')}} !</p>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="contact-form contact-form-support">
                        <form method="post" id="my_submit" enctype="multipart/form-data" @submit="onSubmit">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group mrb-15">
                                <input type="text" name="name" :placeholder="$t('form.name')" class="form-control" required="">
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group mrb-15">
                                <input type="text" name="phone" :placeholder="$t('form.phone')" class="form-control" required="">
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mrb-15">
                                <input type="email" name="email" placeholder="Email" class="form-control" required="">
                              </div>
                            </div>

                            <div class="col-lg-12">
                              <div class="form-group mrb-15">
                                <p>
                                  <strong>
                                      {{$t("question.rate")}}
                                  </strong>
                                </p>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mrb-15 rating">
                                <ul>
                                  <li>
                                    <input type="radio" name="rate" value="1" id="good" selected='true'>
                                    <label for="good">

                                      <img class="lazy" src="/layout/images/icon_good.png" alt=""><span>{{$t('form.rate.t1')}}</span></label>
                                  </li>
                                  <li>
                                    <input type="radio" name="rate" value="2" id="normal">
                                    <label for="normal">
                                        <img class="lazy" src="/layout/images/icon_normal.png" alt=""><span>{{$t('form.rate.t2')}}</span></label>
                                  </li>
                                  <li>
                                    <input type="radio" name="rate" value="3" id="low">
                                    <label for="low">
                                      <img class="lazy" src="/layout/images/icon_low.png" alt=""><span>{{$t('form.rate.t3')}}</span></label>
                                  </li>
                                  <li>
                                    <input type="radio" name="rate" value="4" id="improvement">
                                    <label for="improvement">
                                      <img class="lazy" src="/layout/images/icon_improvement.png" alt=""><span>{{$t('form.rate.t4')}}</span></label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mrb-15">
                                <textarea rows="3" name="content" :placeholder="$t('form.content')" class="form-control" required=""></textarea>
                              </div>
                            </div>

                            <div class="col-lg-12">
                              <div class="form-group mrb-15">
                                <label for="img1">{{$t('form.picture')}}</label>
                                <input type="file" id="img1" name="picture" accept="image/*">

                              </div>
                            </div>


                            <div class="col-lg-12">
                              <div class="form-group">
                                <div class="vcaptcha">
                                  <input class="form-control" :placeholder="$t('form.capcha')" name="proctectcode"  type="text" value="" data-bv-field="captcha">

                                </div>
                              </div>
                              <span class="vimage" v-if="capcha!=null">
                                <img class="lazy" :src="'data:image/jpeg;base64, '+capcha.captchBase64Data" style="float: left;">
                                

                                <div style="border:1px solid #ddd;float: left;padding: 4px; margin-left: 5px;" @click="reloadCapcha()">
                                <img src="@/assets/refresh.png" />
                                 </div>

                                 <input type="hidden"  name="clientId" :value="capcha.clientId" />
                              </span>
                            </div>
                            <div class="col-lg-12 text-center">
                              <div class="form-group">
                                <button type="submit" name="submit" class="btn_news cs-btn-one btn-md btn-round btn-primary-color element-shadow" value="Send">{{$t('form.submit')}}</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>

</template>




<script>
//import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";


  import axios from 'axios';
export default {
  
 
  data() {
    return {
      MyGenral:null,

      myurl:window.my_url,
      my_data:null,
    
      message:false,
      capcha:null,
      meta:{
        title: "Ý KIẾN GÓP Ý VỚI CHÚNG TÔI",
        description: "Ý KIẾN GÓP Ý VỚI CHÚNG TÔI"
      }

      
    };
  },
   metaInfo(){
     return {
        title:this.meta.title,
        description:this.meta.description
     }
  },
  components: {
   
    
    
    
  },
  created(){
         var id=  this.$i18n.locale=='vn' ?  "7634739a-9df7-4e84-a52d-d5160e7b5f17" : "f3bba411-c4bc-44b6-a413-593d52e123e1";        
      axios.get(window.my_api+"api/general/get-general-by-id?generalid="+id,{
                headers:{
                  language: this.$i18n.locale
                }
              })
           .then((res)=>{
              this.MyGenral=res.data.data.flagContent;
           });        

          this.reloadCapcha();
      
  },
  methods:{
        reloadCapcha(){
              axios.post(window.my_api+"api/captchar/generate-captchars")
                  .then( (res)=>{
                  
                    if(res.status==200){

                         this.capcha=res.data.data;
                         console.log(this.capcha);

                                                                   
                    }else {
                      console.log("ERROR",res);
                    }
                     

                });
        },
       onSubmit(event){
           event.preventDefault();

           var form_data = new FormData(document.getElementById('my_submit'));
           form_data.append("captchaCode",form_data.get("proctectcode"));
           axios.post(window.my_api+"api/commentus/create-or-update-commentus",form_data)
                .then((res)=>{
                    if(res.status==200){

                      if(res.data.errors.length>0){
                          alert("Vui lòng nhập đúng capcha.");
                      }else {
                          document.getElementById('my_submit').reset();
                          alert(" Cảm ơn bạn đã liên hệ đến chúng tôi. Chúng tôi sẽ liên hệ đến bạn một cách sớm nhất. ");

                      }

                    }
                });
         

            var form_data_send = new FormData(document.getElementById('my_submit'));
           form_data_send.append("flagcontent",this.MyGenral);
           form_data_send.append("subject","Khách hàng liên hệ");
            axios.post(window.send_mail,form_data_send)
                .then((res)=>{
                    if(res.status==200){

                      console.log(res);

                    }
                });

           return false;
      }
    
  }
}
</script>
